import React, { Component } from "react";
import "./CGU2.css";

class CGU2 extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="content">
          <p className="maySubtitle">Mai 2021</p>
          <h1 className="title">
            Mentions légales et Conditions générales d'utilisation
          </h1>
          <div className="block">
            <p className="partTitle">1. Identification</p>
            <p>
              Le site h8.events.novartis.fr (ci-après le « Site ») est édité par Novartis Pharma S.A.S. société au capital de 43.380.000€, enregistrée au RCS de Nanterre sous le numéro 410 349 070 et dont le siège est situé 8-10 rue Henri Sainte Claire Deville CS 40150 à RUEIL MALMAISON - 92563 Cedex. Téléphone : + 33 (0)1 55 47 60 00.
              <br />
              Le Directeur et Responsable de la publication est Monsieur Frédéric COLLET, Président de Novartis Pharma SAS.
              <br />
              L’hébergement du Site est assuré par la société OVHCloud 2 Rue Kellermann, 59100 Roubaix, France, +33 9 72 10 10 07. La société Henri 8, 86 Avenue des Ternes, 75017 PARIS, Tél. : 01 53 42 11 31 est gestionnaire du Site pour le compte de Novartis Pharma S.A.S et responsable de l’accès sécurisé du Site aux utilisateurs.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">2. Objet du site</p>
            <p>
              2.1 Description du Site
              <br />
              Novartis Pharma S.A.S, ci-après dénommé « Novartis » est un laboratoire pharmaceutique dont l’objectif est de découvrir, développer et commercialiser des produits destinés à soigner les patients, soulager la douleur et améliorer la qualité de vie. 
              <br />
              Le Site est une plateforme de diffusion et de rediffusion d’évènements virtuels organisés par Novartis à destination de professionnels de santé (ci-après la/les « Manifestation(s) »). 
              <br />
              Le Site est accessible uniquement aux professionnels de santé préalablement invités par Novartis à la Manifestation après qu’ils se soient dûment inscrits conformément à l’un des processus d’inscription mentionnés à l’article 4 des CGU, (ci-après dénommés « l’/les « Utilisateur(s)»), aux collaborateurs de Novartis et, le cas échéant, aux prestataires logistiques désignés par Novartis pour l’organisation de la Manifestation.
              <br />
              <br />
              2.2 Contenu
              <br />
              L’accès et l’utilisation de ce Site sont soumis aux dispositions légales en vigueur ainsi qu’aux présentes conditions d’utilisation (ci-après les « Conditions » ou « CGU »). 
              <br />
              En naviguant sur le Site, l'Utilisateur reconnaît avoir lu et adhérer sans limitation ni réserve à l’ensemble des termes des Conditions, s'y conformer et en accepter les principes et modalités.
              <br />
              Novartis s’engage notamment à respecter les dispositions du Code de la santé publique, de la charte de l’ANSM pour la communication et la promotion des produits de santé sur Internet et le e-media du 7 avril 2014, ainsi que du code de l’EFPIA relatives à la communication sur Internet. 
              <br />
              Le contenu de cet espace respecte la réglementation applicable selon les personnes auxquelles il est destiné.
              <br />
              <br />
              2.2.1 Informations
              <br />
              L’ensemble des informations mises à disposition de l’Utilisateur par Novartis, que ce soit directement sur le Site ou via le Site (éventuellement par téléchargement), est défini ci-après par les « Informations ». 
              <br />
              Novartis fait en sorte d’apporter le plus grand soin à la préparation et la mise à disposition des Informations. 
              <br />
              Les Informations disponibles sur le Site sont régulièrement mises à jour et la date de la dernière mise à jour est celle indiquée sur le Site. 
              <br />
              Novartis met tous les moyens en œuvre pour mettre à jour, corriger ou modifier le Site le cas échéant dans les meilleurs délais sous réserve de contraintes notamment techniques. 
              <br />
              <br />
              En cas de nécessité, Novartis en tant qu’éditeur de ce Site, se réserve le droit d’effectuer des retraits, modifications, corrections et/ou améliorations sur les Informations à tout moment et sans préavis.
              <br />
              Novartis n’est pas tenu à une obligation de résultat quant à l’exactitude et à l’exhaustivité des Informations. 
              <br />
              En particulier, les Informations délivrées sous forme orale ou écrite par les orateurs, experts indépendants, externes à Novartis, intervenant dans le cadre de la Manifestation, sont réalisées et présentées sous la seule responsabilité de ces derniers en raison de leur expertise et de leurs connaissances dans le domaine sur lequel porte leur intervention. Novartis ne peut être tenue responsable des éventuelles opinions partagées par les orateurs lors de leurs interventions. 
              <br />
              D’une manière générale, les Informations proposées par Novartis, qu’elles soient délivrées sous forme écrite ou orale, en direct ou en replay, sont fournies à titre indicatif uniquement, et ne sauraient, à aucun moment, engager la responsabilité de Novartis.
              <br />
              <br />
              Par conséquent, l’Utilisateur reconnait être pleinement informé de ce que les Informations mises à sa disposition ne sont ni complètes, ni exhaustives. De même, il reconnait que les informations contenues sur le Site :
              <ul>
                <li>Ne peuvent se substituer à ses propres consultations ou recommandations et, plus largement, au diagnostic médical, consultations ou recommandations de tout professionnel de santé;</li>
                <li>Sont lues, interprétées et/ou utilisées par l’Utilisateur, notamment dans le cadre de sa profession, sous sa seule responsabilité.</li>
              </ul>
              L’Utilisateur reconnait également que les Informations ne constituent en aucun cas, que ce soit directement ou indirectement, de la publicité pour les produits commercialisés par Novartis.
              <br />
              Les Informations sont mises à disposition de l’Utilisateur qui pourra uniquement les visualiser.  Il est rappelé aux Utilisateurs qu’ils s’engagent à :
              <ul>
                <li>se connecter au Site, afin de participer à la Manifestation, dans un lieu isolé, hors présence de tiers, </li>
                <li>ne procéder à aucune capture d’écran, impression ou captation d’images ou de sons lors de la Manifestation.</li>
                <li>de manière générale, respecter la charte de connexion à distance qui leur a été communiquée lors de leur inscription à la Manifestation.</li>
              </ul>
              Par ailleurs, toute utilisation des Informations par l’Utilisateur, en ce incluant l’utilisation à des fins commerciales, est strictement prohibée.
              <br />
              Toute utilisation publique de ces Informations, non autorisée, engage la responsabilité de l’Utilisateur.
              <br />
              <br />
              2.2.2 Fonctionnalités du Site
              <br />
              Les fonctionnalités suivantes pourront être proposées sur le Site :
              <ul>
                <li>Tchat/Module conversationnel</li>
                <li>Quiz/sondages</li>
                <li>whiteboard (tableau blanc)</li>
                <li>Traduction des contenus/présentations</li>
                <li>Questionnaires de satisfaction</li>
              </ul>
              L’Utilisateur est informé que toute interaction via le tchat/module conversationnel et/ou le la fonctionnalité whiteboard éventuellement disponibles sur le Site serait visible de l’ensemble des participants à la Manifestation. Plus généralement, l’utilisation de ces fonctionnalités ou de toute autre fonctionnalité du Site devra être conforme aux dispositions de l’article 5 des CGU.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">3. Engagements et conditions générales d’accès au Site</p>
            <p>
              3.1. Territorialité
              <br />
              Les Informations du Site sont destinées aux professionnels de santé résidant en France et dans les départements, régions et collectivités d’Outre-Mer. Le contenu des Manifestations, qu’elles soient diffusées en direct ou en replay sur le Site est également destiné aux professionnels de santé invités aux Manifestations résidant en France et dans les départements, régions et collectivités d’Outre-Mer. Le cas échéant, les Manifestations pourront être destinées à des professionnels de santé résidant hors de France. Dans cette hypothèse, les contenus liés à ces Manifestations seront identifiés comme tels sur le Site. 
              <br />
              En effet, les Informations figurant/diffusées sur ce Site pourraient ne pas être appropriées en dehors de la France dès lors que la réglementation et les pratiques médicales peuvent être différentes.
              <br />
              <br />
              3.2. Adhésion aux conditions générales d'utilisation
              <br />
              L’Utilisateur doit, préalablement à toute consultation des informations figurant sur le Site ou utilisation des services proposés, prendre connaissance des Conditions.
              <br />
              Il est rappelé qu’en naviguant sur le Site, l'Utilisateur reconnaît adhérer aux termes des présentes Conditions, s'y conformer et en accepter les principes et modalités.
              <br />
              <br />
              3.3. Accès au Site
              <br />
              L’utilisation du Site est gratuite. Elle requiert une connexion et un navigateur internet récent et un équipement informatique de performances raisonnables. Novartis se réserve le droit de résilier, modifier, suspendre, de restreindre ou d’interrompre l’accès à tout ou partie du Site, y compris le contenu, les fonctionnalités ou les heures de disponibilité sans préavis ni indemnités.
              <br />
              Pour accéder au Site, les Utilisateurs doivent s’être préalablement inscrits à la Manifestation conformément à l’un des processus d’inscription décrits ci-dessous en communiquant des informations les concernant, notamment s’ils sont professionnels de santé, leur numéro d’inscription à l’Ordre.
              <ul>
                <li>Inscription sur le Site via un formulaire d’inscription virtuel ;</li>
                <li>Inscription auprès de la société MCI Congrès via un formulaire d’inscription virtuel ;</li>
                <li>Inscription via le portail Novartis destiné aux professionnels de santé et professionnels médicaux dénommé « Portail HCP ».</li>
              </ul>
              Ces processus d’inscription ont pour objet de s’assurer de la qualité des Utilisateurs et de leur permettre d’accéder à la Manifestation et plus largement au contenu du Site.
              <br />
              L’Utilisateur garantit avoir communiqué, lors de la procédure d’inscription, des renseignements exacts et actualisés. Il s’engage à informer Novartis sans délai et par écrit de toute modification desdites informations.
              <br />
              Novartis se réserve le droit de refuser l’accès au Site dans l’hypothèse d’anomalies ou d’incohérences apparaissant lors de la phase d’inscription à la Manifestation.
              <br />
              A l’issue du processus d’inscription l’Utilisateur pourra se voir attribuer un lien de connexion lui permettant d’accéder à la Manifestation sans authentification préalable. Dans cette hypothèse, l’Utilisateur sera seul responsable de la conservation du lien de connexion lui permettant d’accéder au Site. Toute perte ou détournement ou utilisation non autorisée du lien de connexion relèvera de sa propre responsabilité. Il en va de même, de manière générale, de tout acte effectué via ce lien de connexion.
              <br />
              En cas de perte ou détournement de ce lien de connexion, l’Utilisateur devra avertir Novartis sans délai afin que cette dernière prenne les mesures correctives nécessaires afin de permettre à l’Utilisateur d’accéder à la Manifestation. Pour des raisons d’ordre technique ou de sécurité, Novartis se réserve le droit de modifier le lien de connexion éventuellement attribué à l’Utilisateur lors de son inscription.
              <br />
              Novartis peut, sans préavis ni indemnité, suspendre l’accès au Site, en cas d'utilisation illicite ou frauduleuse du lien de connexion au Site éventuellement attribué à l’Utilisateur ou d’une utilisation contraire aux présentes Conditions.
              <br />
              Il est rappelé que l’invitation de l’Utilisateur à la Manifestation est strictement personnelle. De même, les droits accordés à l’Utilisateur et les services y afférents sont personnels et ne sont pas transférables.
              <br />
              A l’issue de la Manifestation, l’Utilisateur pourra accéder au replay de la Manifestation disponible sur le Site. Novartis se réserve le droit à tout moment et pour quelque raison que ce soit, de supprimer le replay de la Manifestation ou de révoquer les droits d’accès au contenu du Site de l’Utilisateur.
              <br />
              <br />
              3.4. Fourniture de documents ou d'applications, logiciels ou widgets, en ligne ou en téléchargement.
              <br />
              Le téléchargement gratuit de documents, d’applications, logiciels ou widgets peut être proposé sur le Site (ci-après les « Eléments téléchargeables »).
              <br />
              Le cas échéant, les Eléments téléchargeables seront téléchargés par l’Utilisateur, sous sa propre responsabilité et aucun droit de propriété intellectuelle ne serait transféré à l’Utilisateur, étant entendu que :
              <ul>
                <li>D’une part l’Utilisateur aura vérifié la compatibilité de son matériel avant de télécharger, d’installer ou d’utiliser les Eléments téléchargeables ;</li>
                <li>D’autre part, l’Utilisateur télécharge les Eléments téléchargeables pour ses besoins propres et à titre strictement personnel.</li>
              </ul>
              Il est expressément interdit à l’Utilisateur de reproduire ou de représenter les Eléments téléchargeables à quelque fin que ce soit autre que pour ses besoins propres. 
              <br />
              En outre, il est expressément interdit à l’Utilisateur de modifier, améliorer, éditer, traduire, décompiler désassembler ou créer une ou plusieurs œuvre(s) dérivée(s) à partir Eléments téléchargeables et notamment de leur contenu, sans l’accord préalable et écrit de Novartis. 
              <br />
              L’Utilisateur s’engage à ne pas porter atteinte aux droits de propriété intellectuelle de Novartis ou, le cas échéant, de tiers par lesquels Novartis a été dûment habilitée à exploiter les droits y afférents.
              <br />
              Toute reproduction, représentation, exploitation de quelque nature que ce soit, de tout ou partie des Eléments téléchargeables sans l’accord préalable écrit de Novartis, est illicite et constitue un acte de contrefaçon passible de sanctions pénales.
              <br />
              Novartis n’est tenu d’aucune garantie de compatibilité avec le matériel de l’Utilisateur. Dans le cadre du téléchargement ou de l’installation des Eléments téléchargeables ou plus généralement de la consultation du Site, Novartis ne garantit pas l’absence d’erreur, de dysfonctionnement, de virus ou de toutes autres causes susceptibles d’altérer, de détériorer et/ou de détruire les fichiers et équipements de l’Utilisateur.  
              <br />
              Novartis ne garantit pas l’exhaustivité des Informations contenues dans les Eléments téléchargeables.
              <br />
              En conséquence, Novartis ne saurait être tenue pour responsable de tout dommage, direct ou indirect subi par l’Utilisateur du fait de la fourniture, l'installation ou de la mise en œuvre d’un Elément téléchargeable.
              <br />
              Novartis n’assure pas la maintenance des Eléments téléchargeables, ni l’assistance à leur utilisation.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">4. Propriété intellectuelle</p>
            <p>
              Il est entendu que tout ce qui figure sur ce Site est, sauf indication contraire, présumé être la propriété de Novartis et/ou pour le moins protégé par le droit d’auteur directement ou par le biais d’une licence, et ne pourra être utilisé en dehors des présentes Conditions et de celles spécifiques au Site sans l’autorisation expresse, préalable et écrite de Novartis. 
              <br />
              A ce titre et conformément au Code de la propriété intellectuelle, seule la copie à usage privé et non commercial est autorisée. Par conséquent, à l’exception de la copie à usage privé, toute reproduction, représentation, diffusion, commercialisation, modification, cession de tout ou partie de l’un quelconque des éléments figurant sur le Site et tout ou partie du Site en général, sans l’autorisation expresse et préalable de Novartis, est strictement interdite
              <br />
              D’autre part, le Site peut contenir ou référencer des marques déposées, brevets, données privées, technologies, produits, procédures, ou autres droits de Novartis et/ou de tiers. A l’exception de l’autorisation limitée, résultant des présentes conditions, aucune licence, ni aucun droit portant sur l’un quelconque de ceux-ci ou d’autres droits ne sont accordés à l’Utilisateur.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">5. Responsabilité de l’Utilisateur</p>
            <p>
              En cas de diffusion de contenus sur le Site par l’Utilisateur, de quelle que nature que ce soit, celui-ci s’engage, sous peine d’exclusion du Site voire d’un engagement de sa responsabilité par Novartis, de n’avoir aucun comportement, ni propos contraire aux lois, à l'ordre public et aux bonnes mœurs, à caractère injurieux, diffamatoire ou discriminatoire, menaçant envers une personne ou un groupe de personnes, violant les dispositions du droit d’auteur, le droit à l’image, à la vie privée d’autrui, le secret médical ou des correspondances. 
              <br />
              De façon générale, l’Utilisateur adopte un comportement courtois et respectueux conformément aux dispositions de la Netiquette notamment à l’égard des autres membres du Site, en particulier dans l’hypothèse où le Site disposerait d’une fonctionnalité tchat/module conversationnel ou whiteboard. L’Utilisateur est informé, conformément aux dispositions de la loi du 21 juin 2004 pour la confiance en l’économie numérique, qu’en cas de demande des autorités compétentes, Novartis est habilité à transmettre toute donnée de connexion permettant l’identification de l’Utilisateur si ce dernier apparaît être à l’origine d’un contenu ou d’un comportement illicite.
              <br />
              Tout Utilisateur qui agit en fraude des présentes Conditions s’expose aux poursuites civiles qui répriment en particulier les atteintes au droit d’auteur, aux droits voisins, aux droits des producteurs de bases de données ainsi qu’aux systèmes de traitement automatisé de données ainsi qu’aux dispositions du Code pénal applicables.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">6. Responsabilité de Novartis</p>
            <p>
              Sans préjudice des autres cas de limitation de responsabilité prévus par les présentes Conditions, la responsabilité de Novartis ne peut être engagée, directement ou indirectement, en raison des dommages subis par l’Utilisateur ou ses équipements électroniques/informatiques/numériques/digitaux, liés : à une interruption du Site, à un cas de force majeure, à l'intrusion non autorisée d'un tiers dans le système informatique de Novartis entraînant la diffusion de codes malveillants notamment aux travers des documents, applications, logiciels et/ou widgets éventuellement disponibles en téléchargement sur le Site, à tout préjudice commercial, perte de clientèle, perte de commande, trouble commercial, perte de bénéfice, perte d'image de marque.
              <br />
              Par ailleurs, la responsabilité de Novartis ne peut être engagée directement ou indirectement, en raison des dommages subis par l’Utilisateur liés à une utilisation non conforme et/ou erronée des Informations figurant sur le Site. 
            </p>
          </div>
          <div className="block">
            <p className="partTitle">7. Liens hypertextes</p>
            <p>
              Les liens « in-line » ou tout autre procédé visant à intégrer tout ou partie de ce Site sur des sites de tiers sont interdits.
              <br />
              Les présentes Conditions s'appliquent uniquement à ce Site, et non pas aux sites web détenus par des tiers. Novartis propose parfois des liens vers d'autres sites web qu’elle juge susceptibles d’intéresser l’Utilisateur. Lors de la création du lien, Novartis s’assure que ces liens au premier degré sont conformes à ses standards. Cependant, du fait de la nature même d'Internet et de son caractère évolutif, Novartis ne peut garantir la conformité du contenu vers lesquels ces liens dirigent l’Utilisateur et de ses éventuelles évolutions, les présentes Conditions n'étant pas applicables aux sites web non-Novartis. Il appartient à l’Utilisateur de consulter et de se référer aux conditions générales d’utilisation de ces sites tiers. En cliquant sur les liens externes, l’Utilisateur reconnait et accepte qu’il quitte le Site et qu’il est redirigé vers un lien externe à Novartis non soumis aux présentes Conditions.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">8. Protection des données à caractère personnel</p>
            <p>
            Certains services du Site nécessitent la communication de données à caractère personnel. Pour toute information supplémentaire sur ce sujet, vous pouvez consulter la Notice d’information sur la protection des données personnelles disponible sur le Site.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">9. Protection des données à caractère personnel</p>
            <p>
              Novartis rappelle qu’il incombe aux professionnels de santé des obligations de pharmacovigilance liées au signalement des effets indésirables conformément à l’article R. 5121-161 du Code de la santé publique cité ci-dessous:
            </p>
            <p className="greyText">
              « Le médecin, le chirurgien-dentiste, la sage-femme ou le pharmacien déclare immédiatement tout effet indésirable suspecté d’être dû à un médicament ou à un produit mentionné à l’article R. 5121-150, dont il a connaissance, au centre régional de pharmacovigilance. Les autres professionnels de santé, les patients et les associations agréées de patients peuvent déclarer tout effet indésirable suspecté d’être dû à un médicament ou à un produit mentionné à l’article R. 5121-150, dont ils ont connaissance, au centre régional de pharmacovigilance. »
            </p>
            <p className="greyText">
              L’Utilisateur peut également déclarer directement tout effet indésirable ou tout risque d’erreur médicamenteuse sur le portail : <p style={{color: "black", display: "inline-block", fontStyle: "normal"}}>www.signalement-sante.gouv.fr</p>
            </p>
            <p className="greyText">
              Le directeur général de l’Agence nationale de sécurité du médicament et des produits de santé rend publics, sur le site internet de l’agence, les informations relatives aux différents modes de notification des effets indésirables suspectés d’être dus à un médicament ou à un produit mentionné à l’article R. 5121-150 par les professionnels de santé et les patients ainsi que le formulaire de déclaration de ces effets indésirables.
            </p>
          </div>
          <div className="block">
            <p className="partTitle">10. Révisions</p>
            <p>
            Novartis peut à tout moment et sans préavis modifier les Conditions en les mettant à jour. Il appartient à l’Utilisateur de se référer régulièrement à la dernière version en vigueur des Conditions dont la date figure au sein des présentes CGU et de s’y conformer.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CGU2;