import React, { Component } from "react";
import "./Cv.css";

class Cv extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="CvContainer">
        <p>Les cvs</p>
      </div>
    );
  }
}

export default Cv;
