import React, { Component } from "react";
import "./Programme.css";
import Prog from "./Container/Prog";
import Cv from "./Container/Cv";
import Quizz from "./Container/Quizz";

class Programme extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: "programme",
    };

    this.handleChangeToProgramme = this.handleChangeToProgramme.bind(this);
    this.handleChangeToIntervenants = this.handleChangeToIntervenants.bind(
      this
    );
    this.handleChangeToQuestionnaires = this.handleChangeToQuestionnaires.bind(
      this
    );
  }

  handleChangeToProgramme() {
    this.setState({ page: "programme" });
  }

  handleChangeToIntervenants() {
    this.setState({ page: "Intervenants" });
  }

  handleChangeToQuestionnaires() {
    this.setState({ page: "Questionnaires" });
  }
  componentWillMount() {}
  componentDidMount() {}

  render() {
    var myPage2 = "";

    if (this.state.page === "programme") {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li className="LIclasses">
                <a
                  className={
                    this.state.page === "programme" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToProgramme}
                >
                  PROGRAMME
                </a>
              </li>
              {/** <li className="LIclasses">
                <a
                  className={
                    this.state.page === "Intervenants" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToIntervenants}
                >
                  INTERVENANTS
                </a>
              </li> */}

              {/*<li className="LIclasses">
                <a
                  className={
                    this.state.page === "Questionnaires" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToQuestionnaires}
                >
                  QUESTIONNAIRES
                </a>
                </li>*/}
            </ul>
          </div>
          <div className="jours3">
            <Prog />
          </div>
        </div>
      );
    } else if (this.state.page === "Intervenants") {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li className="LIclasses">
                <a
                  className={
                    this.state.page === "programme" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToProgramme}
                >
                  PROGRAMME
                </a>
              </li>
              {/** <li className="LIclasses">
                <a
                  className={
                    this.state.page === "Intervenants" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToIntervenants}
                >
                  INTERVENANTS
                </a>
              </li>*/}

              <li className="LIclasses">
                <a
                  className={
                    this.state.page === "Questionnaires" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToQuestionnaires}
                >
                  QUESTIONNAIRES
                </a>
              </li>
            </ul>
          </div>
          <div className="jours3">
            <Cv />
          </div>
        </div>
      );
    } else {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li className="LIclasses">
                <a
                  className={
                    this.state.page === "programme" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToProgramme}
                >
                  PROGRAMME
                </a>
              </li>
              {/**  <li className="LIclasses">
                <a
                  className={
                    this.state.page === "Intervenants" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToIntervenants}
                >
                  INTERVENANTS
                </a>
              </li>*/}

              <li className="LIclasses">
                <a
                  className={
                    this.state.page === "Questionnaires" ? "btnActive" : null
                  }
                  onClick={this.handleChangeToQuestionnaires}
                >
                  QUESTIONNAIRES
                </a>
              </li>
            </ul>
          </div>
          <div className="jours3">
            <Quizz token={this.props.token} />
          </div>
        </div>
      );
    }

    return <div>{myPage2}</div>;
  }
}

export default Programme;
