import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ShareFooter">
        <div className="FooterContainer">
          <a
            href="https://h8.events.novartis.fr/demo/CGU/#/"
            target="_blank"
            rel="noopener noreferrer"
            className="BlueColor"
          >
            {" "}
            Conditions générales d'utilisation.
          </a>{" "}
          <a
            href="https://h8.events.novartis.fr/demo/NoticeWeb/#/"
            target="_blank"
            rel="noopener noreferrer"
            className="BlueColor"
          >
            {" "}
            Notice Web.
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
