import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
//import Home from "../Components/LiveCompenent/Home";
//import Admin from "../Components/AdminComponent/Admin";
//import Chat from "../Components/ModerationComponent/Chat";
//import Moderation from "../Components/ModerationComponent/Moderation";
import Accueil from "../Components/CampusV2/Accueil";

import "react-app-polyfill/ie11";
import CGU1 from "../Components/CGU/CGU1";
import CGU2 from "../Components/CGU/CGU2";

class IndexRoute extends Component {
  render() {
    return (
      <div className="IndexRoute">
        <Router>
          <div>
            <Route exact path="/" component={Accueil} />
            <Route exact path="/:token" component={Accueil} />

            {/*

            <Route exact path="/cgu1" component={CGU1} />
            <Route exact path="/cgu2" component={CGU2} />
             <Route exact path="/cgu1" component={CGU1} />

             <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />

           

              <Route exact path="/:token" component={Admin} />

           
            <Route exact path="/:token" component={Admin} />

            <Route exact path="/:token" component={Moderation} />

             
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/Chat" component={Chat} />
            <Route exact path="/Moderation" component={Moderation} />
            */}
          </div>
        </Router>
      </div>
    );
  }
}

export default IndexRoute;
