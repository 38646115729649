import Axios from "axios";
import React, { Component } from "react";
import "../InscriptionFormulaire.css";
import "./Quizz.css";

const QuizzSection = ({
  title,
  description,
  questions,
  handleAnswerChange,
}) => {
  return (
    <div className="QuizzSection">
      <p className="QuizzSectionTitle">
        {title}
        {description !== "" && description ? (
          <>
            <br />
            {description}
          </>
        ) : (
          []
        )}
      </p>
      {questions.map((question, index) => (
        <QuizzQuestion
          title={question.title}
          answers={question.answers}
          questionIndex={question.questionIndex}
          handleAnswerChange={handleAnswerChange}
          key={index}
        />
      ))}
    </div>
  );
};

const QuizzQuestion = ({
  title,
  answers,
  questionIndex,
  handleAnswerChange,
}) => {
  return (
    <div className="QuizzQuestion" id={`question${questionIndex}`}>
      <p className="QuizzQuestionTitle">{title}</p>
      <div className="QuizzQuestionsWrapper">
        {answers.map((answer, index) => (
          <div
            className={answers.length === 2 ? "QuizzAnswerTwin" : "QuizzAnswer"}
            key={index}
          >
            <p>{answer}</p>
            <input
              type="radio"
              id={`answer${index + 1}`}
              name={`question${questionIndex}`}
              className="QuizzRadio"
              value={answer}
              onChange={(e) =>
                handleAnswerChange(questionIndex, e.target.value, title)
              }
            />
          </div>
        ))}
      </div>
      <hr className="solid" />
    </div>
  );
};

class Quizz extends Component {
  constructor(props) {
    super(props);
    this.interval = -1;
    this.state = {
      answers: [],
      popup: false,
    };
    this.handleValidate = this.handleValidate.bind(this);
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.Popup = this.Popup.bind(this);
  }

  /**
   * Fonction qui permet de récupérer la réponse choisie
   * @param {*} questionIndex - Numéro de la question
   * @param {*} answer - Réponse choisie
   */
  handleAnswerChange(questionIndex, answer, questionTitle) {
    let answers = [...this.state.answers];

    if (answers.find((answer) => answer.questionIndex === questionIndex)) {
      const index = answers.findIndex(
        (answer) => answer.questionIndex === questionIndex
      );
      if (index !== -1) {
        answers[index].answer = answer;
        this.setState({ answers });
      }
    } else {
      answers.push({ questionIndex, answer, questionTitle });
      this.setState({ answers });
    }
  }

  handleValidate() {
    const answers = [...this.state.answers];
    Axios.post(
      // ! Changer le lien pour le site déployé
       `https://h8.events.novartis.fr/JAURA/api/users/answers`,
      //`https://novartisric.herokuapp.com/users/answers`,
      {
        token: this.props.token,
        answers,
      }
    ).then((res) => {
      this.setState({ popup: true });
    });
  }

  Popup() {
    if (this.state.popup) {
      var body = document.body;
      var html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      var width = document.documentElement.clientWidth;
      var content = null;

      if (this.interval === -1) {
        this.interval = setInterval(() => {
          this.setState({ popup: false });
          this.setState({ answers: [] });

          // Pour enlever visuellement les réponses
          // 24 - 1 correspond au nombre de questions
          for (let i = 1; i < 24; i++) {
            const question = document.getElementById(`question${i}`);
            const inputs = question.getElementsByClassName("QuizzRadio");
            for (let x = 0; x !== inputs.length; x++) {
              inputs[x].checked = false;
            }
          }
          clearInterval(this.interval);
          this.interval = -1;
        }, 4000);
      }

      content = (
        <div className="SmallPopup">
          <p className="textPopup">Merci !</p>
          <p className="textPopup">
            Votre réponse a été envoyée
            <br />
            Merci de votre participation
          </p>
        </div>
      );
      return (
        <div
          className="popup"
          style={{
            width: width,
            height: height,
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "10",
          }}
        >
          {content}
        </div>
      );
    } else {
      return [];
    }
  }

  render() {
    return (
      <div>
        {this.Popup()}
        <div className="QuizzContainer">
          <p style={{ textAlign: "justify" }}>
            Soucieux d'améliorer nos réunions et afin de mieux répondre à vos
            attentes, nous vous remercions de bien vouloir prendre quelques
            minutes pour compléter ce questionnaire de satisfaction.
          </p>

          <QuizzSection
            handleAnswerChange={this.handleAnswerChange}
            title="Programme"
            description="Comment évaluez-vous le contenu de la réunion ?"
            questions={[
              {
                title: "Introduction",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 1,
              },
              {
                title:
                  "Faut-il supplémenter en Fer tous nos patients IC ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 2,
              },
              {
                title: "Les vaccins sont-ils vraiment efficaces chez le patient IC ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 3,
              },
              {
                title:
                  "Quelle place pour les nouvelles thérapies pharmacologiques dans les recommandations ESC 2021 ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 4,
              },
              {
                title: "Prise en charge des fuites valvulaires secondaires dans l’IC : IM et IT",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 5,
              },
              {
                title: "A vos claviers : session questions / réponses",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 6,
              },
              {
                title: "Conclusion",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 7,
              },
              {
                title:
                  "Quelle est votre opinion concernant la qualité des échanges de la réunion ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Pas satisfait",
                ],
                questionIndex: 8,
              },
              {
                title:
                  "Quelle est votre opinion concernant le choix des thématiques abordées dans cette réunion ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Pas satisfait",
                ],
                questionIndex: 9,
              },
              {
                title:
                  "Quelle est votre opinion concernant les sessions interactives animées ?",
                answers: [
                  "Très satisfaisant",
                  "Satisfaisant",
                  "Peu satisfaisant",
                  "Insatisfait",
                ],
                questionIndex: 10,
              },
            ]}
          />

          <QuizzSection
            handleAnswerChange={this.handleAnswerChange}
            title="Aspects Logistiques"
            questions={[
              {
                title: "La date de la réunion vous a-t-elle convenu ?",
                answers: ["Oui", "Non"],
                questionIndex: 11,
              },
              {
                title: "L’horaire choisi pour la réunion était-il pertinent ?",
                answers: ["Oui", "Non"],
                questionIndex: 12,
              },
              {
                title: "Le temps consacré à la réunion vous a-t-il convenu ?",
                answers: ["Oui", "Non"],
                questionIndex: 13,
              },
              {
                title:
                  "Le temps consacré à la présentation / aux présentations ont-ils été ?",
                answers: ["Trop longs", "Suffisants", "Trop courts"],
                questionIndex: 14,
              },
              {
                title: "Le temps consacré aux échanges a-t-il été ?",
                answers: ["Trop longs", "Suffisants", "Trop courts"],
                questionIndex: 15,
              },
              {
                title:
                  "Etes-vous satisfait de la qualité de l'organisation logistique ?",
                answers: [
                  "Très satisfait",
                  "Satisfait",
                  "Peu satisfait",
                  "Insatisfait",
                ],
                questionIndex: 16,
              },
              {
                title:
                  "Qu’avez-vous pensé du format à distance de la réunion ?",
                answers: [
                  "Très satisfait",
                  "Satisfait",
                  "Peu satisfait",
                  "Insatisfait",
                ],
                questionIndex: 17,
              },
              {
                title: "Qu’avez-vous pensé de l’outil utilisé ?",
                answers: [
                  "Très satisfait",
                  "Satisfait",
                  "Peu satisfait",
                  "Insatisfait",
                ],
                questionIndex: 18,
              },
              {
                title: "Qu'avez-vous pensé du niveau d'interactions ?",
                answers: [
                  "Très satisfait",
                  "Satisfait",
                  "Peu satisfait",
                  "Insatisfait",
                ],
                questionIndex: 19,
              },
            ]}
          />

          <QuizzSection
            handleAnswerChange={this.handleAnswerChange}
            title="Appréciation globale"
            questions={[
              {
                title: "Avez-vous jugé cette réunion ?",
                answers: [
                  "Très intéressante",
                  "Intéressante",
                  "Peu intéressante",
                  "Très peu intéressante",
                ],
                questionIndex: 20,
              },
              {
                title: "Cette réunion a-t-elle répondu à vos attentes ?",
                answers: ["Oui", "Non"],
                questionIndex: 21,
              },
              {
                title:
                  "Si vous en aviez la possibilité, participeriez-vous à une prochaine réunion avec Novartis ?",
                answers: ["Oui", "Peut-être", "Non"],
                questionIndex: 22,
              },
              {
                title:
                  "Recommanderiez-vous cette réunion à l'un(e) de vos confrères/soeurs ?",
                answers: ["Oui", "Peut-être", "Non"],
                questionIndex: 23,
              },
              {
                title:
                  "Auriez-vous préféré que cette réunion se passe en présentiel ?",
                answers: ["Oui", "Non"],
                questionIndex: 24,
              },
            ]}
          />

          <p style={{ fontSize: 12, marginBottom: 40 }}>
            {/*
            
             Si vous souhaitez voir aborder d'autres sujets et/ou thématiques
            lors d'une prochaine réunion organisée par Novartis, nous vous
            remercions de bien vouloir contacter par mail : xxx@novartis.com +
            titre du contact (MSL, Medical advisor).*/}
          </p>
        </div>
        <div className="buttonDiv">
          <button onClick={this.handleValidate} className="validateButton">
            Valider les réponses
          </button>
        </div>
      </div>
    );
  }
}

export default Quizz;
