import React, { Component } from "react";
import "./CGU1.css";

class CGU1 extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="content">
          <h1 className="title">
            Novartis – Notice d’information sur la protection des données personnelles
            <br />
            Site internet
          </h1>
          <div className="block">
            <p className="maySubtitle">Mai 2021</p>
            <p>
              Cette Notice d’information sur la protection des données personnelles vous est adressée car vous visitez un site internet d’une des sociétés du groupe Novartis.
              Nous procédons au traitement d’informations vous concernant, informations qui constituent des « <p className="boldText">données à caractère personnel</p> » et nous portons une grande attention à la protection de vos données personnelles et de votre vie privée.
              <br />
              <br />
              Novartis Pharma SAS / Sandoz décide pourquoi et comment un traitement de vos données personnelles est effectué et agit ainsi comme « <p className="boldText">responsable du traitement</p> ».
              Dans cette Notice d’information sur la protection des données personnelles, « <p className="boldText">nous</p> » ou « <p className="boldText">notre</p> » désignent Novartis Pharma SAS / Sandoz.
              <br />
              <br />
              Cette Notice d’information sur la protection des données personnelles est divisée en deux parties.
              La Partie I contient des informations pratiques sur les données personnelles <p className="underlineText">spécifiques</p> que nous traitons quand vous visitez notre site internet <a target="_blank" rel="noreferrer" className="linkText" href="https://h8.events.novartis.fr">https://www.h8.events.novartis.fr</a>, pourquoi nous les traitons et comment.
              La Partie II contient des informations générales sur les données personnelles <p className="underlineText">standards</p> techniques ou transactionnelles que nous traitons sur les visiteurs de nos sites internet et sur les utilisateurs de nos applications, la base légale nous permettant d’utiliser vos données personnelles, ainsi que vos droits relatifs au traitement de vos données personnelles.
              <br />
              <br />
              Nous vous invitons à lire attentivement cette Notice d’information sur la protection des données personnelles et,
              pour toute question supplémentaire, nous vous invitons à contacter votre responsable local de la protection des données personnelles,
              à l’adresse email <a target="_blank" rel="noreferrer" className="linkText" href="mailto:droit.information@novartis.com">droit.information@novartis.com</a> (pour Novartis Pharma SAS) / <a target="_blank" rel="noreferrer" className="linkText" href="mailto:contact.dataprivacy@sandoz.com">contact.dataprivacy@sandoz.com</a> (pour Sandoz).
            </p>
          </div>
          <div className="block">
            <p className="partTitle">Partie I - Informations clé</p>
            <p>
              Novartis Pharma SAS / Sandoz procède au traitement de données personnelles vous concernant quand vous visitez notre site internet <a target="_blank" rel="noreferrer" className="linkText" href="https://h8.events.novartis.fr">https://www.h8.events.novartis.fr</a>.
              Ce site internet est une plateforme internet dédiée à la mise en ligne d’événements virtuels et à la gestion d’inscription des participants concernés.
              <br />
              <br />
              Dans le cadre de nos événements,
              si vous souhaitez signaler un événement sanitaire indésirable rapporté avec l’un de nos produits,
              nous vous invitons au préalable à lire attentivement la notice générale d’information sur les données personnelles dédiée disponible via ce lien : <a target="_blank" rel="noreferrer" className="linkText" href="https://www.novartis.fr/notices">https://www.novartis.fr/notices</a> et à prendre connaissance de l’information suivante.
              Si vous êtes un professionnel de santé,
              nous vous rappelons que vous avez l’obligation légale de fournir au préalable les informations contenues dans la notice générale d’information sur les données personnelles dédiée à la personne exposée à l’événement sanitaire indésirable rapporté avec l’un de nos produits.
              <br />
              <br />
              Nous vous informons que nous utilisons les données collectées lors de ce signalement afin d’assurer le respect de notre obligation légale.
              Elles seront conservées pour une durée conforme à la réglementation en la matière.
              Vous disposez d’un droit d’accès, de rectification,
              d’obtenir la limitation du traitement de vos données personnelles et de donner des directives sur le sort de vos données après votre décès.
              Toutefois, vous ne disposez pas d’un droit de suppression, d’opposition, ni de demander le cas échéant la portabilité de vos données, dans la mesure où ce traitement de données personnelles est fondé sur le respect par Novartis Pharma SAS / Sandoz d’une obligation légale.
            </p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Données personnelles</p>
            <p>
              Nous collectons les données personnelles spécifiques suivantes vous concernant :
            </p>
            <ul>
              <li>Des données personnelles obligatoires : titre (Pr / Dr), civilité, nom et prénom, spécialité principale, rôle (médecin / orateur / staff), statut participation (invité / annulé / show / no show), adresse email, numéro RPPS, questions posées sur le Chat ;</li>
              <li>Des données personnelles facultatives : statut hospitalier ou libéral, nom de l'établissement hospitalier ou officine, adresse professionnelle, code postal, ville, téléphone ;</li>
              <li>Toute donnée personnelle nécessaire pour assurer la modération.</li>
            </ul>
            <p>
              Ces informations peuvent provenir directement de vous (par exemple, en remplissant un formulaire en ligne ou en interagissant avec le site internet ou l’application), être fournies par des tiers ou obtenues par le biais de sources fiables accessibles au public, après avoir obtenu votre consentement selon le droit applicable à la protection des données personnelles.
            </p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Finalités spécifiques pour lesquelles des données sont utilisées</p>
            <p>Nous utilisons les informations collectées pour les finalités spécifiques suivantes :</p>
            <ul>
              <li>Pour accéder à la plateforme d’inscription et vous inscrire à l’événement ;</li>
              <li>Pour vous permettre d’accéder à l’événement virtuel le(s) jour(s) de l’événement et le cas échéant au replay (si disponible) ;</li>
              <li>Pour vous permettre de participer à la fonction chat, aux éventuels sondages, répondre au questionnaire de satisfaction, télécharger divers documents mis à votre disposition (si existants) ou d’autres fonctions interactives inhérentes à l’événement pour lequel vous avez été convié ;</li>
              <li>Pour effectuer une modération de la fonction chat en conformité avec nos obligations légales et règlementaires.</li>
            </ul>
            <p>
              Veuillez noter que nous pourrons aussi utiliser les données collectées pour d’autres finalités habituelles (par exemple, pour mesurer les utilisations de notre site internet ou application), telles que décrites dans la Partie II ci-après.
            </p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Tiers avec lesquels nous partageons vos données</p>
            <p>Nous partageons vos données personnelles avec les tiers suivants :</p>
            <ul>
              <li>Henri 8 : notre agence logistique en charge des invitations et de l’organisation de l’événement virtuel ;</li>
              <li>OVH Cloud France (Roubaix) : hébergeur du site internet</li>
            </ul>
            <p>
              Veuillez noter que nous pouvons être obligés de partager vos données avec d’autres destinataires, toujours en respectant des conditions strictes, telles qu’expliquées dans la Partie II (par exemple, toute entité du groupe Novartis si l’entité qui collecte les données n’est pas la même que celle qui va les utiliser).
            </p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Durée de conservation</p>
            <p>
              Les données traitées sont systématiquement détruites dès que le traitement de données personnelles n’est plus nécessaire au regard de la gestion de l’événement concerné, sauf demande expresse de Novartis (replays, etc.).
              <br/>
              Les données personnelles traitées dans le cadre de nos obligations légales ou règlementaires seront conservées pour une durée conforme à la règlementation.
              <br />
              En cas de replay : les données seront conservées pour une durée maximum de 1 an.
            </p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Cookies et autres technologies similaires</p>
            <p>Nous n’utilisons pas de cookies sur le site internet <a target="_blank" rel="noreferrer" className="linkText" href="https://h8.events.novartis.fr">https://www.h8.events.novartis.fr</a></p>
          </div>
          <div className="borderedBlock">
            <p className="underlineTitle">Point de contact</p>
            <p>
              Si vous avez des questions concernant le traitement de vos données personnelles,
              veuillez contacter le responsable local de la protection des données personnelles, à l’adresse email <a target="_blank" rel="noreferrer" className="linkText" href="mailto:droit.information@novartis.com">droit.information@novartis.com</a> (pour Novartis Pharma SAS) ou <a target="_blank" rel="noreferrer" className="linkText" href="mailto:contact.dataprivacy@sandoz.com">contact.dataprivacy@sandoz.com</a> (pour Sandoz).
            </p>
          </div>
          <div className="block">
            <p className="partTitle">Partie II - Informations générales</p>
            <p>
              La Partie II de cette Notice d’information sur la protection des données personnelles donne plus de détails sur le contexte dans lequel nous sommes amenés à traiter vos données personnelles et explique vos droits et nos obligations.
            </p>
            <ul className="numberList">
              <li className="partTitle">
                Sur quel fondement utilisons-nous vos données personnelles ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Nous traitons vos données personnelles uniquement si ce traitement est justifié et conforme aux dispositions légales en vigueur.
                  Par conséquent, nous traiterons vos données personnelles uniquement si :
                  <ul className="complexList">
                    <li>☒ Nous avons obtenu votre consentement préalable ;</li>
                    <li>☐ Le traitement est nécessaire à l’exécution d’un contrat auquel vous êtes partie ou à l’exécution de mesures précontractuelles prises à votre demande ;</li>
                    <li>☒ Le traitement est nécessaire au respect de nos obligations légales ou règlementaires ;</li>
                    <li>
                      ☒ Le traitement est nécessaire aux fins des intérêts légitimes que nous poursuivons et n’affecte pas indûment vos intérêts ou libertés et droits fondamentaux.
                      <br />
                      <br />
                      Veuillez noter que quand nous traitons vos données personnelles sur ce dernier fondement, nous cherchons toujours à maintenir un équilibre entre nos intérêts légitimes et votre vie privée.
                      <br />
                      Exemples d’« intérêts légitimes » pour lesquels des traitements de données sont effectués :
                      <ul>
                        <li>Faire bénéficier de services de meilleure qualité (par exemple, nous pouvons choisir d’utiliser certaines plateformes mises en place par certains prestataires pour traiter des données) ;</li>
                        <li>Fournir nos services ;</li>
                        <li>Prévenir la fraude ou les activités criminelles, les mauvaises utilisations de nos services et assurer la sécurité de nos systèmes informatiques, nos architectures et nos réseaux ;</li>
                        <li>Vendre une partie de nos activités ou nos actifs ou permettre l’acquisition de tout ou partie de nos activités ou actifs par un tiers ;</li>
                        <li>Et respecter nos objectifs en matière de responsabilité sociale et organisationnelle.</li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </li>
              <li className="partTitle">
                Qui a accès à vos données personnelles et à qui sont-elles transférées ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Nous ne vendrons, ne partagerons, ni ne transférerons à des tiers aucune autre donnée personnelle vous concernant que celles indiquées dans cette Notice d’information.
                  <br />
                  Dans le cadre de nos activités et pour les mêmes finalités que celles listées dans cette Notice, vos données personnelles peuvent être accessibles ou transférées aux catégories de destinataires identifiés dans la Partie I et aux catégories de destinataires suivants, dans la mesure où ils justifient d’un besoin pour accomplir ces finalités :
                  <ul>
                    <li>Notre personnel (y compris le personnel, les départements ou les autres sociétés du groupe Novartis) ;</li>
                    <li>Nos autres fournisseurs et prestataires de services ;</li>
                    <li>Nos prestataires de systèmes d’information, nos prestataires de service Cloud, nos prestataires de bases de données et nos consultants ;</li>
                    <li>Nos partenaires commerciaux avec lesquels nous-mêmes ou les sociétés du groupe Novartis échangent des services ;</li>
                    <li>Tout tiers à qui nous avons assigné ou transféré nos droits ou obligations ; </li>
                    <li>Et nos conseillers ou avocats dans le cadre d’une vente ou d’un transfert de tout ou partie de nos activités ou actifs.</li>
                  </ul>
                  <br />
                  Les tiers listés ci-dessus sont contractuellement tenus de protéger la confidentialité et la sécurité de vos données personnelles en conformité avec la loi applicable.
                  <br />
                  Vos données personnelles peuvent aussi être accessibles par, ou transférées à tout organisme de régulation, autorité étatique, juridiction ou organisme public national et/ou international, lorsque nous y sommes contraints par la loi ou les règlements, ou à leur demande.
                  <br />
                  Les données personnelles vous concernant que nous collectons peuvent aussi être traitées, accessibles ou conservées dans un pays en dehors du pays où Novartis Pharma SAS / Sandoz est située, pays qui peut ne pas offrir le même niveau de protection des données personnelles.
                  <br />
                  <p className="partTitle">Transferts internationaux</p>
                  Si nous transférons vos données personnelles à des sociétés situées dans d’autres juridictions, nous nous assurerons de protéger vos données personnelles i) en appliquant le niveau de protection requis par les réglementations de protection des données personnelles et de la vie privée applicables à Novartis Pharma SAS / Sandoz ii) en agissant conformément à nos politiques internes et standards et, iii) en transférant seulement vos données personnelles sur le fondement des clauses contractuelles types approuvées par la Commission européenne, sauf indication en sens contraire. Vous pouvez demander plus d’informations au sujet des transferts internationaux de données personnelles et obtenir une copie des protections adéquates mises en place en exerçant vos droits comme décrit à la Section 6 ci-dessous.
                  <br />
                  <br />
                  Concernant les transferts de données personnelles aux autres sociétés du groupe, le groupe Novartis a adopté des Règles Internes d’Entreprise qui sont un système de principes, de règles et d’outils destiné à garantir des niveaux effectifs de protection des données personnelles en cas de transfert de données en dehors de l’Espace Economique Européen (c’est-à-dire, les Etats membres de l’Union européenne ainsi que l’Islande, le Liechtenstein et la Norvège, « <p className="boldText">EEE</p> »), et de la Suisse. Pour en savoir plus sur les Règles Internes d’Entreprise de Novartis, cliquez sur le lien : <a target="_blank" rel="noreferrer" className="linkText" href="https://www.novartis.fr/politique-de-confidentialite#regles">https://www.novartis.fr/politique-de-confidentialite#regles</a>.
                </p>
              </li>
              <li className="partTitle">
                Comment protégeons-nous vos données personnelles ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Nous avons mis en place des mesures techniques et organisationnelles afin de garantir un niveau approprié de sécurité et de confidentialité à vos données personnelles.
                  <br />
                  Ces mesures prennent en compte :
                  <ul style={{listStyleType: "decimal"}}>
                    <li>L’état de l’art des technologies ;</li>
                    <li>Le coût de leur mise en œuvre ;</li>
                    <li>La nature des données ;</li>
                    <li>Et le risque du traitement.</li>
                  </ul>
                  La finalité qui en résulte est leur protection contre la destruction ou l’altération de manière accidentelle ou illicite, la perte accidentelle, la divulgation ou l’accès non autorisé et contre d’autres formes illicites de traitement.
                  <br />
                  De plus, nous accomplissons les obligations suivantes :
                  <ul>
                    <li>Collecter et traiter uniquement les données personnelles qui sont adéquates, pertinentes et non excessives, en vue de poursuivre les finalités déterminées ci-dessus ;</li>
                    <li>Assurer que vos données personnelles sont à jour et exactes (pour ce faire, nous pouvons vous demander de confirmer l’exactitude des données personnelles que nous détenons à votre sujet. Vous êtes aussi invité à nous informer de façon spontanée de tout changement dans votre situation personnelle afin que nous puissions nous assurer que vos données personnelles sont mises à jour) ;</li>
                    <li>Et nous pouvons traiter des données sensibles vous concernant que vous nous avez volontairement fournies conformément aux lois de protection des données applicables et uniquement pour les finalités énoncées ci-dessus, sous la responsabilité de l’un de nos représentants soumis à une obligation de secret professionnel ou de confidentialité.</li>
                  </ul>
                </p>
              </li>
              <li className="partTitle">
                Combien de temps conservons-nous vos données personnelles ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Nous conservons vos données personnelles uniquement le temps nécessaire afin d’accomplir la finalité pour laquelle elles ont été collectées ou pour remplir des obligations légales ou règlementaires.
                  <br />
                  Sauf indication contraire dans la Partie I de cette Notice d’information sur la protection des données personnelles, la durée de conservation est de 24 mois suivant votre dernière utilisation ou dernier accès au site concerné ou à notre application. A l’issue du délai de conservation, vos données personnelles seront supprimées de nos systèmes actifs.
                </p>
              </li>
              <li className="partTitle">
                Comment utilisons-nous les cookies ou les technologies similaires sur nos sites internet et applications ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  5.1. Cookies
                  <br />
                  Les cookies sont de petits fichiers texte qui sont déposés et/ou lus sur votre équipement terminal (ordinateur, tablette, téléphone portable, etc.) quand vous visitez nos sites internet ou quand vous utilisez nos applications. Nous utilisons des cookies pour les finalités susmentionnées et conformément à cette Notice d’information sur la protection des données personnelles.
                  <br />
                  Nous n’utilisons pas des cookies pour obtenir des connaissances nécessaires pour comprendre comment nos sites internet et applications sont utilisés, dans le but de les améliorer au bénéfice de nos utilisateurs. Les données personnelles générées grâce aux cookies sont collectées de manière pseudonyme.
                  <br />
                  <br />
                  5.2. Autres technologies
                  <br />
                  Nous pouvons aussi utiliser d’autres technologies sur nos sites internet et applications afin de collecter et traiter vos données personnelles pour les mêmes finalités que celles indiquées ci-dessus, y compris :
                  <ul>
                    <li>Des balises internet (tels que les balises d’action, des GIFs single-pixel, des clear GIFs, des GIFs invisibles et des GIFs 1-by-1, qui sont des technologies nous permettant de tracer le parcours des utilisateurs sur nos sites internet) ;</li>
                    <li>Et la technologie Adobe Flash (y compris les cookies Flash, à moins que vous en ayez décidé autrement dans vos paramètres).</li>
                  </ul>
                </p>
              </li>
              <li className="partTitle">
                Quels sont vos droits et comment les exercer ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Vous pouvez exercer les droits suivants dans les conditions et selon les limites prévues par la loi :
                  <ul>
                    <li><p className="boldText">Accéder à l’ensemble de vos données</p> : vous pouvez obtenir des informations relatives au traitement de vos données ainsi qu’une copie de celles-ci ;</li>
                    <li><p className="boldText">Rectifier et mettre à jour vos données personnelles</p> si vous pensez par exemple qu’une de vos informations est incorrecte, obsolète ou incomplète ;</li>
                    <li><p className="boldText">Effacer</p> : vous pouvez demander la suppression de vos données personnelles ;</li>
                    <li><p className="boldText">Demander une limitation des traitements des informations</p> ;</li>
                    <li><p className="boldText">Vous opposer au traitement</p> de vos données personnelles (pour tout ou partie) ;</li>
                    <li><p className="boldText">Retirer votre consentement</p> à tout moment pour le traitement de vos données soumis à votre consentement, sans que cela n’affecte la licéité du traitement effectué avant ce retrait ;</li>
                    <li><p className="boldText">Donner des instructions</p> sur le sort de vos données après votre décès ;</li>
                    <li><p className="boldText">Demander la portabilité</p> c’est-à-dire que les données personnelles que vous nous avez fournies vous soient restituées ou transférées à une personne de votre choix, dans un format structuré, couramment utilisé et lisible par machine.</li>
                  </ul>
                  <br />
                  <br />
                  Veuillez noter cependant que, dans certains cas, votre refus d’accepter les cookies ou le paramétrage de votre navigateur peut affecter votre expérience de navigation et peut vous empêcher d’utiliser certaines fonctions de nos sites internet ou applications.
                  <br />
                  Si vous avez une question ou si vous souhaitez exercer vos droits décrits ci-dessus, vous pouvez envoyer un email à l’adresse <a target="_blank" rel="noreferrer" className="linkText" href="mailto:droit.information@novartis.com">droit.information@novartis.com</a> (pour Novartis Pharma SAS) ou <a target="_blank" rel="noreferrer" className="linkText" href="mailto:contact.dataprivacy@sandoz.com">contact.dataprivacy@sandoz.com</a> (pour Sandoz). Une photocopie de votre pièce d’identité pourra vous être demandée afin de vous identifier, étant entendu que nous l’utilisons uniquement pour vérifier votre identité et que nous ne conservons pas cette photocopie après vérification de votre identité. Quand vous nous envoyez cette photocopie, veillez à occulter votre photographie et votre numéro national d’immatriculation ou son équivalent.
                  <br />
                  Si vous n’êtes pas satisfait de la façon dont nous traitons vos données personnelles, vous pouvez adresser une réclamation à notre délégué à la protection des données à l’adresse email <a target="_blank" rel="noreferrer" className="linkText" href="mailto:global.privacy_office@novartis.com">global.privacy_office@novartis.com</a>, qui étudiera vos demandes.
                  Dans tous les cas, vous avez aussi le droit de déposer plainte auprès des autorités de protection des données compétentes (pour la CNIL : <a target="_blank" rel="noreferrer" className="linkText" href="www.cnil.fr">www.cnil.fr</a>).
                </p>
              </li>
              <li className="partTitle">
                Quelles sont les données techniques et transactionnelles que nous pouvons collecter à votre sujet ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  7.1. Catégories de données techniques et transactionnelles
                  <br />
                  En plus de toutes les informations que nous collectons vous concernant au titre de la Partie I de cette Notice d’information sur la protection des données personnelles, nous pouvons collecter différentes sortes de données personnelles techniques et transactionnelles à votre sujet, données nécessaires pour assurer le bon fonctionnement de nos sites internet et applications, y compris :
                  <ul>
                    <li>Des informations relatives à votre navigateur et votre appareil (par exemple, le nom de domaine de votre fournisseur d’accès internet, le type et la version de votre navigateur, votre système d’exploitation et plateforme, votre résolution écran, le fabriquant et le modèle de votre appareil) ;</li>
                    <li>Des statistiques concernant l’utilisation de notre site internet ou application (par exemple, des informations sur les pages visitées, les recherches effectuées, le temps passé sur notre site internet) ;</li>
                    <li>Des données d’usage (c’est-à-dire la date et la durée d’accès à notre site internet ou application, les documents téléchargés) ;</li>
                    <li>La localisation de votre appareil quand vous utilisez notre application, dans les conditions prévues par la réglementation et à moins que vous n’ayez désactivé cette fonction en changeant les paramètres de votre navigateur ;</li>
                    <li>Et de manière plus générale, toute information que vous nous fournissez en utilisant notre site internet ou application.</li>
                  </ul>
                  <br />
                  <br />
                  7.2. Pourquoi collectons-nous des données techniques et transactionnelles ?
                  <br />
                  Nous traitons toujours vos données personnelles pour une finalité déterminée et uniquement si ces données sont pertinentes et nécessaires pour atteindre celle-ci. En plus de toutes les finalités qui vous ont déjà été communiquées dans la Partie I de cette Notice d’information sur la protection des données personnelles, nous procédons aussi au traitement de vos données personnelles collectées pendant votre utilisation de nos sites internet ou applications pour les finalités standards suivantes :
                  <ul>
                    <li>Gérer les utilisateurs (par exemple, enregistrement, gestion des comptes, réponse aux questions et fournir une aide technique) ;</li>
                    <li>Gérer et améliorer nos sites internet et applications (par exemple, diagnostic des problèmes serveur, optimisation du trafic, intégration et optimisation des pages internet si nécessaire) ;</li>
                    <li>Mesurer l’utilisation de nos sites internet et applications (par exemple, en préparant des statistiques relatives au trafic, en recueillant des informations sur les comportements des utilisateurs et les pages qu’ils visitent) ;</li>
                    <li>Améliorer et personnaliser votre expérience, adapter le contenu en fonction de vos préférences (par exemple, en enregistrant vos sélections et préférences ou en utilisant des cookies) ;</li>
                    <li>Vous envoyer des services personnalisés et du contenu ;</li>
                    <li>Améliorer la qualité de nos services et étendre nos activités commerciales ;</li>
                    <li>Surveiller et prévenir la fraude, les violations et toute autre potentielle mauvaise utilisation de nos sites internet et applications ;</li>
                    <li>Répondre à une requête officielle d’une autorité publique ou judiciaire avec l’autorisation nécessaire ;</li>
                    <li>Gérer nos ressources informatiques, y compris la gestion des infrastructures et la continuité des affaires ;</li>
                    <li>Protéger les intérêts économiques de notre société, assurer la conformité de nos activités et le signalement des abus (notamment la conformité avec nos politiques internes et les exigences légales locales, le respect de nos obligations fiscales, la gestion des cas allégués d’abus ou agissements frauduleux ; mener des activités d’audits et se défendre dans le cadre d’un litige) ;</li>
                    <li>Conservation des archives et des enregistrements ;</li>
                    <li>Et toute autre finalité imposée par la loi et les autorités.</li>
                  </ul>
                </p>
              </li>
              <li className="partTitle">
                Comment serez-vous informé des changements survenus dans notre Notice d’information sur la protection des données personnelles ?
                <p style={{color: "black", fontFamily: "VoltaRoman"}}>
                  Vous serez informé de tous futurs changements ou ajouts concernant le traitement de vos données personnelles décrit dans cette Notice d’information à travers nos canaux de communication habituels (par exemple, par email) ou à travers nos sites internet ou applications (via des bannières, des pop-ups ou autres mécanismes de notification).
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default CGU1;