import React, { Component } from "react";
import "./InscriptionFormulaire.css";
import axios from "axios";
import loading from "../../assets/loading.png";
import { Firebase_Config } from "../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class InscriptionFormulaire extends Component {
  constructor(props, context) {
    super(props, context);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.state = {
      nom: "",
      prenom: "",
      email: "",
      centre: "",
      messages: "",
      confirmation: "",
      certifie: false,

      errors: {},
      popup: false,
      sended: false,
      valid: false,
      statusToken: "",
      validation: true,
      player: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeCentre = this.handleChangeCentre.bind(this);
    this.handleChangeRadioToTrue = this.handleChangeRadioToTrue.bind(this);
    this.handleChangeRadioToFalse = this.handleChangeRadioToFalse.bind(this);
    this.handleChangeRadioCertifie = this.handleChangeRadioCertifie.bind(this);

    this.handleValidation = this.handleValidation.bind(this);
    this.setAlert = this.props.alert;
    this.interval = -1;
  }

  componentDidMount() {}

  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (this.state.nom === "") {
      formIsValid = false;
      errors["nom"] = "Cannot be empty";
    }

    if (this.state.prenom === "") {
      formIsValid = false;
      errors["prenom"] = "Cannot be empty";
    }

    if (this.state.nom !== "undefined") {
      if (!this.state.nom.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["nom"] = "Only letters";
      }
    }

    if (this.state.prenom !== "undefined") {
      if (!this.state.prenom.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["prenom"] = "Only letters";
      }
    }

    //Email
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    let lastAtPos = this.state.email.lastIndexOf("@");
    let lastDotPos = this.state.email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.email.length - lastDotPos > 2
      )
    ) {
      this.setAlert("Veuillez saisir une adresse mail valide");
    } else if (this.state.confirmation === "") {
      this.setAlert("Veuillez confirmer votre participation");
    } else if (
      this.state.certifie === false &&
      this.state.confirmation != false
    ) {
      this.setAlert(
        "Veuillez cocher la case Je certifie avoir pris connaissance des conditions..."
      );
    } else {
      this.setState({
        sended: true,
        popup: true,
      });

      this.ConfirmeInscription();
    }

    event.preventDefault();
  }

  handleChangeRadioToTrue(event) {
    this.setState({
      confirmation: true,
    });
  }

  handleChangeRadioToFalse(event) {
    this.setState({
      confirmation: false,
    });
  }

  handleChangeRadioCertifie(event) {
    this.setState({
      certifie: true,
    });
  }

  handleChangeNom(event) {
    this.setState({ nom: event.target.value });
  }

  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeCentre(event) {
    this.setState({ centre: event.target.value });
  }

  ConfirmeInscription = () => {
    //console.log(this.state.confirmation);
    if (this.state.confirmation === true) {
      axios
        .post(`https://novartisprojet.herokuapp.com/users/update`, {
          confirmation: this.state.confirmation,
          email_invite: this.state.email,
        })
        .then((res) => {
          this.setState({
            valid: true,
            validation: true,
          });
        })
        .catch((err) => {
          this.setState({ validation: false });
        });
    } else {
      axios
        .post(`https://novartisprojet.herokuapp.com/users/update`, {
          confirmation: this.state.confirmation,
          email_invite: this.state.email,
        })
        .then((res) => {
          this.setState({
            valid: true,
            validation: this.state.confirmation,
          });
        })
        .catch((err) => {
          this.setState({ validation: false });
        });
    }
  };

  Popup() {
    if (this.state.popup) {
      var body = document.body;
      var html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      var width = document.documentElement.clientWidth;
      var content = null;
      if (this.state.valid && this.state.confirmation === true) {
        if (this.interval === -1) {
          this.interval = setInterval(() => {
            this.setState({ popup: false });
            clearInterval(this.interval);
            this.interval = -1;
          }, 15000);
        }
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Merci !</p>
            <p className="textPopup">
              Votre inscription à la web-conférence « Titre de la webconférence
              » a bien été prise en compte et vous allez recevoir un email de
              confirmation.
            </p>
            <p className="textPopup">
              Si vous ne recevez pas l'email de confirmation, veuillez vérifier
              dans votre boîte SPAM ou attendez quelques minutes.
            </p>
            <p className="textPopup">
              Vous allez revenir sur la page d'ici quelques secondes.
            </p>
            <p className="textPopup">à Bientôt !</p>
          </div>
        );
      } else if (this.state.valid && this.state.confirmation === false) {
        if (this.interval === -1) {
          this.interval = setInterval(() => {
            this.setState({ popup: false });
            clearInterval(this.interval);
            this.interval = -1;
          }, 15000);
        }
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Merci.</p>
            <p className="textPopup">
              Votre réponse a bien été prise en compte et vous allez recevoir un
              email de confirmation.
            </p>
            <p className="textPopup">
              Si vous ne recevez pas l'email de confirmation, veuillez vérifier
              dans votre boîte SPAM ou attendez quelques minutes.
            </p>
            <p className="textPopup">
              Vous allez revenir sur la page d'ici quelques secondes.
            </p>
            <p className="textPopup">à Bientôt !</p>
          </div>
        );
      } else {
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Veuillez patienter.</p>
            <p className="textPopup">
              Vous serez inscrit d'ici une dizaine de secondes maximum.
            </p>
            <img src={loading} className="loadingLogo" alt="loadingIndicator" />
          </div>
        );
      }
      return (
        <div
          className="popup"
          style={{
            width: width,
            height: height,
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "10",
          }}
        >
          {content}
        </div>
      );
    }
  }

  render() {
    var urlLive =
      "https://h8.events.novartis.fr/JAURAV2/live/#/" + this.props.token;

    var templatePlayer = "";
    if (this.state.player === 1) {
      templatePlayer = (
        <div>
          <div className="PlayCadre">
            <a className="linkPlay" href={urlLive} target="_blank">
              <img
                src="https://h8.events.novartis.fr/demo/IMG/play.png"
                alt=""
                className="imgPlay"
              />
            </a>
          </div>

          <div className="titreTeaser">
            {/** <p>Teaser video </p>*/}
            <p>Cliquez sur le bouton play ci-dessus pour accéder au live.</p>
          </div>
        </div>
      );
    } else {
      templatePlayer = (
        <div className="PlayCadre">
          <div className="linkPlay2">
            <p>Patience !</p>

            <p>Votre Web-conférence commence le jeudi 2 décembre 2022</p>
            <p> de 20h00 à 21h40</p>
          </div>
        </div>
      );
    }
    if (this.state.validation === true) {
      var template = (
        <div>
          <div className="titleInscription titleInscription2">
            <div className="title1">INSCRIPTION A LA WEB-CONFÉRENCE</div>
            <div className="title2">Titre de la web-conférence</div>
            <div className="title3">Jeudi 2 décembre 2022 de 20h00 à 21h40</div>
          </div>

          <div className="BlueCadre">
            {templatePlayer}
            <div className="titreTeaser">
              {/** <p>Teaser video : Dr Marie MARTIN, rhumatologue</p>*/}
            </div>
          </div>
        </div>
      );
    } else {
      var template = (
        <form>
          <div className="titleInscription">
            <div className="title1">INSCRIPTION A LA WEB-CONFÉRENCE</div>
            <div className="title2"> Titre de la web-conférence</div>
            <div className="title3">
              {" "}
              Jeudi 2 décembre 2022 de 20h00 à 21h40
            </div>
          </div>
          <div>
            <span className="titleConfirme">
              Je confirme ma participation à la web-conférence :
            </span>

            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="optradio"
                  onChange={this.handleChangeRadioToTrue}
                />
                Oui
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="optradio"
                  onChange={this.handleChangeRadioToFalse}
                />
                Non
              </label>
            </div>
          </div>

          <div className="flex-container2">
            <div className="inside1">
              <div className="form-group testContainer">
                <input
                  type="text"
                  className="form-control"
                  name="prenom"
                  placeholder="Prénom*"
                  required
                  value={this.state.prenom}
                  onChange={this.handleChangePrenom}
                  disabled
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email*"
                  required
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  disabled
                />
                {this.state.errors["email"]}
              </div>
            </div>

            <div className="programme2 inside2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="nom"
                  placeholder="Nom*"
                  required
                  value={this.state.nom}
                  onChange={this.handleChangeNom}
                  disabled
                />
                <span style={{ color: "red", fontSize: 8 }}>
                  {this.state.errors["nom"]}
                </span>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="centre"
                  placeholder="Etablissement*"
                  required
                  value={this.state.centre}
                  onChange={this.handleChangeCentre}
                  disabled
                />
              </div>
            </div>
          </div>
          <p className="textHerader">
            Nous vous précisons que votre participation lors de l'événement ne
            donnera lieu à aucune prise en charge ou remboursement de la part de
            Novartis relatif à l'hospitalité ou à des déplacements.
          </p>

          <div className="bottomInscription">
            <div>Charte de connexion pour les participants :</div>
            <br />
            <div>
              Afin de participer à cet événement via une connexion à distance,
              vous vous engagez à respecter les conditions ci-dessous :
            </div>
            <ul>
              <li>
                Vous connecter à distance dans un lieu isolé, hors présence de
                tiers non invités à l'événement.
              </li>
              <li>
                Ne pas transmettre le lien de connexion spécifique à l'événement
                à un tiers sans acceptation préalable de Novartis.
              </li>
              <li>
                Ne procéder à aucune capture d'écran, impression ou captation
                d'images ou de sons lors de la connexion à distance.
              </li>
            </ul>
            <div className="form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                onClick={this.handleChangeRadioCertifie}
              />
              <label className="form-check-label">
                Je certifie avoir pris connaissance des conditions d’invitation
                et de la Charte de la Connexion à distance
              </label>
            </div>
          </div>

          <div className="btnValider2" onClick={this.handleSubmit}>
            <img
              src="https://h8.events.novartis.fr/demo/IMG/BTN_Valider-09.png"
              alt="btn valider"
            />
          </div>
        </form>
      );
    }
    return (
      <div className="ContainerInscription">
        {this.Popup()}
        <div className="FormContainer">
          <div>
            <div className="FormContainer1"></div>
            <div className="FormContainer2">
              {template}
              <div className="FooterContainerIns">
                <div className="FooterContactINS">
                  <strong> CONTACT</strong>
                  <p>
                    Pour toute question, nous vous invitons à contacter l'agence{" "}
                    <strong>HENRI 8 :</strong>
                  </p>
                </div>

                <div className="FooterInfoIns">
                  <div>
                    Adresse email :
                    <br />
                    Téléphone :
                  </div>
                  <div className="bloc2">
                    <a href="mailto: Novartis_Pharma_France@henri8.com">
                      novartis_pharma_france@henri8.com
                    </a>
                    <br />
                    <span className="FooterSm">01 44 90 00 59</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InscriptionFormulaire;
