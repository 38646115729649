import React, { Component } from "react";
import "./Accueil.css";
import InscriptionFormulaire from "./InscriptionFormulaire";
import Footer from "./Footer";
import Programme from "./Programme";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      popupAlert: false,
      monToken: "",
    };
    this.popup = this.popup.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {
    // console.log(this.props.match.params.token);

    this.setState({
      monToken: this.props.match.params.token,
    });
  }

  setAlert(alert) {
    this.setState({
      alert: alert,
      popupAlert: true,
    });
  }

  popup() {
    if (this.state.popupAlert) {
      this.intervalAlert = setInterval(() => {
        this.setState({ popupAlert: false });
        clearInterval(this.intervalAlert);
      }, 10000);
      return (
        <div className="alertPopup">
          <p style={{ marginTop: "1.5%", fontSize: "24px" }}>Attention</p>
          <p>{this.state.alert}</p>
        </div>
      );
    }
  }

  render() {
    if (this.state.monToken === undefined) {
      var template = (
        <div>
          <div>.</div>
          <div className="titleWebconference">
            <div className="titleweb1">Web-conférence</div>
            <div className="titleweb2">TITRE DE LA WEB-CONÉRENCE</div>
            <div className="titleweb3">
              Jeudi 2 décembre 2022 de 20h00 à 21h40
            </div>
          </div>
          <div className="ContainerV2">
            <div className="ContainerV2In1">
              <Programme token={this.props.match.params.token} />
            </div>
            <div className="ContainerV2In2">
              <InscriptionFormulaire
                alert={this.setAlert}
                token={this.props.match.params.token}
              />{" "}
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      var template = (
        <div>
          <div>.</div>
          <div className="titleWebconference">
            <div className="titleweb1">Web-conférence</div>
            <div className="titleweb2">TITRE DE LA WEB-CONÉRENCE</div>
            <div className="titleweb3">
              Jeudi 2 décembre 2022 de 20h00 à 21h40
            </div>
          </div>
          <div className="ContainerV2">
            <div className="ContainerV2In1">
              <Programme token={this.props.match.params.token} />
            </div>
            <div className="ContainerV2In2">
              <InscriptionFormulaire
                alert={this.setAlert}
                token={this.props.match.params.token}
              />{" "}
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    return (
      <div>
        {this.popup()}
        {template}
      </div>
    );
  }
}

export default Header;
