import React, { Component } from "react";
import "./Prog.css";

class Prog extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ProgContainer">
        <div className="SessionCampus">
          <ul>
            <li style={{ marginBottom: 15 }}>
              <strong>Modérateurs : </strong>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                In hendrerit augue non ligula placerat
              </span>
            </li>
            <div className="DateProgramme">
              <span className="DateProgrammeDate"> 20h00-20h05</span>{" "}
            </div>
            <li>
              <strong>Introduction</strong>
              <br></br>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                Ac dapibus nunc sagittis.In hendrerit augue non ligula placerat,
                ac dapibus nunc sagittis.
              </span>
            </li>

            <div className="DateProgramme">
              <br></br>
              <span className="DateProgrammeDate"> 20h05-20h20</span>{" "}
            </div>

            <li>
              <strong>
                Actualités sur la prise en charge en post-Infarctus du myocarde
              </strong>
              <br></br>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                Pr xx, Lyon
              </span>
            </li>

            <div className="DateProgramme">
              <br></br>
              <span className="DateProgrammeDate"> 20h50-21h05</span>{" "}
            </div>
            <li>
              <strong>Coeur artificiel : où en sommes-nous ?</strong>
              <br></br>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                Dr xx, Lyon
              </span>
            </li>
            <div className="DateProgramme">
              <br></br>
              <span className="DateProgrammeDate"> 21h05-21h35</span>{" "}
            </div>
            <li>
              <strong>A vos claviers : session questions / réponses</strong>
              <br></br>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                Tous
              </span>
            </li>
            <div className="DateProgramme">
              <br></br>
              <span className="DateProgrammeDate"> 21h35-21h40</span>{" "}
            </div>
            <li>
              <strong>Conclusion</strong>
              <br></br>
              <span style={{ color: "#0460a9", fontStyle: "italic" }}>
                Pr xx, Clermont-Ferrand & Dr xx, Grenoble
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Prog;
